import React from "react"
import Layout from "@components/layout";

// Import SVG
import corp_busimg1 from '@images/busi_img01.webp';
import corp_busimg2 from '@images/busi_img02.webp';
import corp_busimg3 from '@images/busi_img03.webp';
import corp_busimg4 from '@images/busi_img04.webp';

// 関数呼び出し
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Business = () => {
  const pageFXUrl = '/kaigai-fx/';
  
  // Seo情報設定
  const pathName = 'business';
  const seoTag = SeoContents({ Path:pathName });

  return (
  <>
    <Layout>
      {seoTag}
      <div className={pathName}> 
        {/* FV */}
        <div className="corp_h1">
          <h1 className="font-semibold ">Business</h1>
        </div>
        <div>
          <div className="text-center mb-[50px] md:mt-[60px] mt-[50px] "> 
            <h2 className="font-semibold tracking-wide text-xl md:text-[18px]">Main business</h2>
      
          </div>
          
          <div className="bus_flex max-w-4xl mx-auto  md:max-w-4xl px-6 mb-[60px]">
            <div className="bus_thum  md:mr-[5%]">
              <p className="mb-5   w-full"><img src={corp_busimg1} alt=""/></p>
            </div>
            <div className="bus_thum02 md:mt-9">
              <h3 className="text-[14px]  font-semibold leading-6">蓄積したノウハウを利用した<br/>自社オウンドメディアの運営</h3>
              <p className="text-[13px] mt-2">集客の向上を目的とした、SEO対策やデジタルマーケティング、快適で軽快な動作のサイト構築に取り組んだオウンドメディアの運営を行っております。</p>
            </div>
          </div>

          <div className="bus_flex max-w-4xl mx-auto  md:max-w-4xl px-6 mb-[60px]">
            <div className="bus_thum od1  md:ml-[5%]">
              <p className="mb-5"><img src={corp_busimg2} alt=""/></p>
            </div>
            <div className="bus_thum02 od2  md:mt-9">
              <h3 className="text-[14px]  font-semibold leading-6">効果的なsns利用とweb広告の運用<br/>デジタルマーケティング支援</h3>
              <p className="text-[13px] mt-2">SNSを活用したマーケティング支援やSEO対策、事業規模や広告予算に応じて最適な広告をご提案します。<br/>
              計測された数値を元にロジカルな運用で取り組んでいます。
              </p>
            </div>
          </div>

          <div className="bus_flex max-w-4xl mx-auto  md:max-w-4xl px-6 mb-[60px]">
            <div className="bus_thum md:mr-[5%]">
              <p className="mb-5"><img src={corp_busimg3} alt=""/></p>
            </div>
            <div className="bus_thum02  md:mt-8">
              <h3 className="text-[14px]  font-semibold leading-6">クライアントの要望と利用者のニーズに応える<br/>トレンドをおさえたWeb制作や開発</h3>
              <p className="text-[13px] mt-2">SEO対策やサイトの読み込みスピード向上を重要視したサイト制作を請け負っております。<br/>
              クライアントの満足感と利用者の快適性を両立させた提案を行います。
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="text-center mb-[60px]  "> 
            <h2 className="pt-[80px] font-semibold tracking-wide text-xl md:text-[18px] ">Media</h2>
    
          </div>
          <div className="bus_flex max-w-4xl mx-auto  md:max-w-4xl px-6 mb-[60px]">
            <div className="bus_thum md:mr-[5%]">
              <p className="mb-5"><a href={pageFXUrl} aria-label="海外FX200"><img src={corp_busimg4} alt=""/></a></p>
            </div>
            <div className="bus_thum02  md:mt-4">
              <h3 className="text-[14px]  font-semibold leading-6">Good At Trading</h3>
              <p className="md:mt-5 mt-5 font-semibold text-[17px] ">海外FX業者の比較やブローカー別に特徴をまとめた 比較サイトです。</p>
              <p className="text-[13px] mt-2">これからFXを始めたいと思っているビギナーの方からすでに取引を行っている経験者のために、各種お役立ち情報を更新してまいります。</p>
              <p className="mt-5 text-xs mb-20"><a href={pageFXUrl} className="underline hover:no-underline">ページはこちら</a></p>
            </div>
          </div>
        </div>
        
      </div>
    </Layout> 
  </>
  )
}
export default Business

